<template>
  <component :is="layout">
    <div class="fadeInUp" role="main">
      <div class="row fadeInUp" role="main">
        <div class="col-12 col-md-3">
          <ConfigurationNav />
        </div>
        <div class="col-12 col-md-9" v-if="questionnaire && questionnaire.id">
          <div class="card border-0 shadow-sm mb-4">
            <div class="card-header">
              <h5 class="h5">Edit questionnaire<a :href="`https://customer.automation.app/form/${questionnaire.id}/test`"
                  target="_blank" class="btn btn-outline-primary btn-sm float-right">View questionnaire</a></h5>

            </div>
            <form class="form" @submit.prevent="onSubmit">
              <div class="card-body">
                <div class="form-group row">
                  <div class="form-group col-md-12">
                    <label for="name" class="control-label">Name</label>
                    <input id="name" v-model="questionnaire.name" required class="form-control"
                      :class="{ error: hasErrors.name }" type="text" name="name" maxlength="100" />
                    <span v-show="errors.has('name')" class="invalid-feedback">{{ errors.first("name") }}</span>
                  </div>
                  <div class="form-group col-md-12">
                    <label for="welcome_text" class="control-label">Welcome text</label>
                    <textarea id="welcome_text" v-model="questionnaire.welcome_text" class="form-control"
                      :class="{ error: hasErrors.welcome_text }" name="welcome_text" />
                    <span v-show="errors.has('welcome_text')" class="invalid-feedback">{{ errors.first("welcome_text")
                    }}</span>
                  </div>
                  <div class="form-group col-md-12">
                    <label for="thank_you_text" class="control-label">Thank you message</label>
                    <textarea id="thank_you_text" v-model="questionnaire.thank_you_text" class="form-control"
                      :class="{ error: hasErrors.thank_you_text }" name="thank_you_text" />
                    <span v-show="errors.has('thank_you_text')" class="invalid-feedback">{{
                        errors.first("thank_you_text")
                    }}</span>
                  </div>
                </div>
                <h5 class="h5">Fields</h5>
                <div v-for="(fieldConfig,
                index) in questionnaire.questionnaireFields" :key="fieldConfig.id" class="form-group row border-bottom">
                  <div class="form-group col-md-6 col">
                    <input id="field-name" v-model="questionnaire.questionnaireFields[index].name" required
                      class="form-control" :class="{ error: hasErrors.fieldName }" type="text" name="name"
                      maxlength="100" />
                    <span v-show="errors.has('fieldName')" class="invalid-feedback">{{ errors.first("fieldName")
                    }}</span>
                  </div>
                  <div class="form-group col-md-5 col">
                    <select id="field-type" v-model="questionnaire.questionnaireFields[index].type" required
                      class="form-control" :class="{ error: hasErrors.fieldType }" type="text" name="key"
                      maxlength="100">
                      <option v-for="type in fieldTypes" :key="type.value" :value="type.value">
                        {{ type.text }}
                      </option>
                    </select>
                    <span v-show="errors.has('fieldType')" class="invalid-feedback">{{ errors.first("key") }}</span>
                  </div>
                  <div class="form-group col-md-1 col">
                    <DeleteIconButton class="float-right" :name="fieldConfig.name"
                      :uri="`questionnaire/field/${fieldConfig.id}`" @deleted="deleteField(fieldConfig.id)" />
                  </div>
                  <div class="form-group col-md-12 col">
                    <label for="description" class="control-label">Description</label>
                    <input id="description" v-model="questionnaire.questionnaireFields[index].description" class="form-control"
                      :class="{ error: hasErrors.description }" type="text" name="description" />
                    <span v-show="$validator.errors.has('description')" class="invalid-feedback">{{
                        $validator.errors.first("description")
                    }}</span>
                  </div>
                  <div class="form-group col-md-12" v-if="fieldConfig.type === 'select'">
                    <span class="mr-2 border rounded p-2 font-weight-bold">Options:</span>
                    <span class="mr-2 border rounded p-2" v-for="option in fieldConfig.options" :key="option">{{ option
                    }}</span>
                  </div>
                </div>
              </div>
              <div class="card-footer d-flex justify-content-end">
                <ButtonLoading :loading="loading" :class="'btn-primary'" :title="'Update'" />
              </div>
            </form>
          </div>
          <h5 class="h5">Add field</h5>
          <div class="card border-0 shadow-sm mb-4">
            <NewField @fieldAdded="addField" :questionnaireKeys="fieldKeys" :questionnaireId="questionnaire.id"
              :organizationId="questionnaire.organization_id" />
          </div>
          <DeleteButton class="mb-4" :uri="`questionnaire/config/${questionnaire.id}`" :name="questionnaire.name"
            @deleted="deletequestionnaire" />
          <div class="mt-3">
            <h4>Data submit format:</h4>
            <p>
              You can find or create your api keys
              <router-link :to="{ name: 'organization.integrations.index' }">here</router-link>
            </p>
            <pre class="border rounded p-3 pt-7 m-0 d-flex align-items-center w-100 text-center code-sample">
              curl -X POST -H "Content-Type: application/json" -H "apikey: XYZ" -d '{{
                  getReplyFormat()
              }}' "https://api.automation.app/api/v1/form/{{ questionnaire.id }}"
            </pre>
          </div>
        </div>
      </div>
    </div>
  </component>
</template>

<script>
import jsonAPI from "@/api";
import Default from "@/layouts/default.vue";
import ButtonLoading from "@/components/Form/ButtonLoading.vue";
import DeleteIconButton from "@/components/Form/DeleteIconButton.vue";
import DeleteButton from "@/components/Form/DeleteButton.vue";
import ConfigurationNav from "@/components/Nav/ConfigurationNav.vue";
import NewField from "@/components/Questionnaire/NewField.vue";

export default {
  name: "questionnaireCreate",
  components: {
    Default,
    ButtonLoading,
    DeleteButton,
    DeleteIconButton,
    ConfigurationNav,
    NewField,
  },
  beforeRouteEnter(to, from, next) {
    const _id = to.params.id;
    jsonAPI.get("questionnaire/config/" + _id).then((response) => {
      next((vm) => {
        vm.questionnaire = response.data;
        vm.name = vm.questionnaire.name;
        vm.key = vm.questionnaire.key;
      });
    });
  },
  data: function () {
    return {
      layout: "Default",
      loading: false,
      addFieldLoading: false,
      questionnaire: {},
      name: "",
      key: "",
      hasErrors: false,
      fieldName: "",
      fieldKey: "",
      fieldType: "string",
      options: [],
      option: "",
      fieldTypes: [
        {
          value: "string",
          text: "String",
        },
        {
          value: "integer",
          text: "Integer",
        },
        {
          value: "float",
          text: "Float",
        },
        {
          value: "bool",
          text: "Boolean",
        },
        {
          value: "select",
          text: "Select",
        },
      ],
    };
  },
  computed: {
    fieldKeys() {
      return this.questionnaire.questionnaireFields.map((field) => field.key);
    },
  },
  methods: {
    convertToKey(text) {
      /*eslint-disable */
      return text
        .toLowerCase()
        .replace(/[!\"#$%&'\(\)\*\+,\.\/:;<=>\?\@\[\\\]\^`\{\|\}~]/g, "")
        .replace(/ /gi, "-");
      /*eslint-enable */
    },
    validateKey(fieldKey) {
      let result = true;
      this.questionnaire.questionnaireFields.forEach((element) => {
        if (element.target == fieldKey) {
          result = false;
        }
      });
      return result;
    },
    onSubmit() {
      this.loading = true;
      this.$validator.validateAll().then((result) => {
        if (result) {
          const key = this.convertToKey(this.name);
          this.questionnaire.key = key;
          jsonAPI
            .put(
              "questionnaire/config/" + this.questionnaire.id,
              this.questionnaire
            )
            .then(() => {
              this.loading = false;
              window.Bus.$emit("flash-message", {
                text: "Update success",
                type: "success",
              });
            })
            .catch((error) => {
              Object.keys(error.response.data.errors).forEach((key) => {
                this.$validator.errors.add({
                  field: key,
                  msg: error.response.data.errors[key][0],
                });
              });
              this.loading = false;
              window.Bus.$emit("flash-message", {
                text: "validation.error",
                type: "error",
              });
            });
        } else {
          this.loading = false;
        }
      });
    },
    addField(field) {
      this.questionnaire.questionnaireFields.push(field);
    },
    deleteField(_id) {
      this.questionnaire.questionnaireFields = this.questionnaire.questionnaireFields.filter(
        (el) => el.id !== _id
      );
    },
    addOption() {
      this.options.push(this.option);
      this.option = "";
    },
    deletequestionnaire() {
      this.$router.push("/forms");
    },
    getReplyFormat() {
      const fields = {};
      this.questionnaire.questionnaireFields.forEach((element) => {
        fields[element.target] = "test";
      });
      return JSON.stringify({
        group: this.questionnaire.key,
        fields: fields,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.code-sample {
  white-space: pre-wrap;
}
</style>
