














































import { Component, Inject, Prop, Vue } from 'vue-property-decorator';
import jsonAPI from "@/api";
import convertToKey from '@/utils/convertToKey';
import { Validator } from 'vee-validate/types/vee-validate';
import ButtonLoading from "@/components/Form/ButtonLoading.vue";

@Component({
  $_veeValidate: { validator: "new" },
  components: {
    ButtonLoading
  }
})
export default class NewField extends Vue {
  @Inject('$validator') public $validator!: Validator;

  @Prop() questionnaireKeys!: string[];
  @Prop() questionnaireId!: string;
  @Prop() organizationId!: string;

  fieldName = '';
  description = '';
  addFieldLoading = false;
  options: string[] = [];
  option = '';
  fieldType = '';
  hasErrors = {
    fieldName: false,
    fieldType: false,
    description: false,
  };
  fieldTypes = [
    {
      value: "string",
      text: "String",
    },
    {
      value: "integer",
      text: "Integer",
    },
    {
      value: "float",
      text: "Float",
    },
    {
      value: "bool",
      text: "Boolean",
    },
    {
      value: "select",
      text: "Select",
    },
  ]

  public validateKey(fieldKey: string) {
    let result = true;
    this.questionnaireKeys.forEach((element) => {
      if (element == fieldKey) {
        result = false;
      }
    });
    return result;
  }

  /**
   * saveField
   */
  public async saveField() {
    this.addFieldLoading = true;
    this.$validator.validateAll().then((result) => {
      if (result) {
        const fieldKey = convertToKey(this.fieldName);
        const keyValid = this.validateKey(fieldKey);
        if (keyValid) {
          jsonAPI
            .post("questionnaire/field", {
              organization_id: this.organizationId,
              name: this.fieldName,
              type: this.fieldType,
              description: this.description,
              target: fieldKey,
              options: this.options,
              questionnaire_config_id: this.questionnaireId,
            })
            .then((response) => {
              this.addFieldLoading = false;
              this.$emit("fieldAdded", response.data);
              window.Bus.$emit("flash-message", {
                text: "Update success",
                type: "success",
              });
              this.fieldName = "";
              this.fieldType = "string";
              this.description = "";
              this.option = "";
              this.options = [];
            })
            .catch((error) => {
              Object.keys(error.response.data.errors).forEach((key) => {
                this.$validator.errors.add({
                  field: key,
                  msg: error.response.data.errors[key][0],
                });
              });
              this.addFieldLoading = false;
              window.Bus.$emit("flash-message", {
                text: "validation.error",
                type: "error",
              });
            });
        } else {
          this.addFieldLoading = false;
          window.Bus.$emit("flash-message", {
            text: "Name is not unique",
            type: "error",
          });
        }
      } else {
        this.addFieldLoading = false;
      }
    });
  }

  public addOption(): void {
    this.options.push(this.option);
    this.option = "";
  }
}
